import { stringify } from "query-string";
import { client_id, redirect_uri, authority, response_type, scope, post_logout_redirect_uri } from '../helpers/constants'
// Static oidc params for a single provider

export const beginAuth = ({ state, nonce }) => {
  // Generate authentication URL
  const params = stringify({
    client_id,
    redirect_uri,
    response_type,
    scope,
    state,
    nonce
  });

  // const authUrl = `${authority}/auth?${params}`; //onelogin
  const authUrl = `${authority}/authorize?${params}`; //azure
  window.location.assign(authUrl);
};

export const signOut = () => {
  const id_token_hint = JSON.parse(localStorage.auth).id_token
  console.log(id_token_hint)
  const params = stringify({
    post_logout_redirect_uri,
    id_token_hint,
  })

  const signOutUrl = `${authority}/logout?${params}/`;
  window.location.assign(signOutUrl);
}
