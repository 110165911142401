
import React from 'react'
import { Message, Container, Button } from 'semantic-ui-react'
import { Link } from "@reach/router";

const UnauthorizedBanner = () => (
  <Message
    negative>
    <Message.Header>Your session has expired</Message.Header>
    <Container style={{ marginTop: '1.5em' }}><Link to="/"><Button positive>Sign in with SSO</Button></Link></Container>
  </Message>
)

export default UnauthorizedBanner
