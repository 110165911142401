export const capitalize = (str) => {
    if (typeof str === 'string') {
        return str.replace(/^\w/, c => c.toUpperCase());
    } else {
        return '';
    }
}

export const sum = (array) => {
    return array.reduce((a, c) => a += c, 0)
}

export const removeLocationHash = () => {
    const noHashURL = window.location.href.replace(/auth#.*$/, '');
    window.history.replaceState('', document.title, noHashURL)
}