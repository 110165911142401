
import React from 'react'
import { Container, Menu, Image } from 'semantic-ui-react';
import logo from '../images/paybyphone-logo.png';

const MenuBar = props => {
  return (
    <>
      <Menu fixed='top' >
        <Container>
          <Menu.Item as='a' header>
            <Image size='mini' src={logo} style={{ marginRight: '1.5em' }} />
          Apigee Configuration Console
        </Menu.Item>
          <Menu.Item as='a'>Home</Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item
              name={props.name}
              onClick={false}
            />
            {/* <Menu.Item
              name='sign out'
              onClick={() => { oidcApi.signOut(localStorage.auth) }}
            /> */}
          </Menu.Menu>
        </Container>
      </Menu>
    </>
  )
}

export default MenuBar
