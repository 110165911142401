
import React, { useState, useEffect } from 'react'
import { Modal, Header, Icon, Button } from 'semantic-ui-react'

const ConfirmationModal = props => {
  const [open, setOpen] = useState(props.active)

  useEffect(() => {
    setOpen(props.active)
  }, [props.active])

  const handleOnSaveConfig = () => {
    props.onSave()
    setOpen(false)
  }

  const handleOnClose = () => {
    props.onCancel()
    props.setPresave(false)
    setOpen(false)
  }

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
      dimmer="blurring"
    >
      <Header icon>
        <Icon name='exclamation circle' />
        Throttle Production Traffic
      </Header>
      <Modal.Content>
        <p>
          You are about to change the settings for traffic throttling of PayByPhone clients. Are you sure you want to do this?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' inverted onClick={handleOnClose}>
          <Icon name='remove' /> No
        </Button>
        <Button loading={props.isConfigUpdating} color='green' inverted onClick={handleOnSaveConfig}>
          <Icon name='checkmark' /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmationModal
