import React, {useState} from 'react'
import { removeLocationHash } from '../helpers';
import Consumer from '../pages/proxies/Consumer'
import { parse } from "query-string";
import jwtDecode from "jwt-decode";
import { isEmpty } from "lodash";


const Auth = ({ location }) => {
  const [profile, setProfile] = useState({})
  if (isEmpty(profile)) {
    const hash = location.hash;
    const response = parse(hash);
    if (response.error) {
      alert(response.error_description);
    //   return <Redirect to="/home" />
    } else {
      const { access_token, id_token } = response
      const jwt = jwtDecode(response.id_token);
      setProfile(jwt)
      localStorage.setItem('auth', JSON.stringify({ access_token, id_token, ...jwt }))
    }
  } else {
    removeLocationHash()
    return <Consumer proxyName="consumer"/>
  }
};

export default Auth;
