import React, { useState } from 'react';
import Login from './pages/Login.js'
import Auth from './pages/Auth.js'
import { Link, Router } from "@reach/router"
import { Menu, Container, Dropdown, Grid, Header, Image } from 'semantic-ui-react'
import ProxyHome from './pages/proxies/ProxyHome'
import Consumer from './pages/proxies/Consumer'
import './App.css';
import logo from './images/paybyphone-logo.png'; // Tell webpack this JS file uses this image

const FixedMenuLayout = props => {
  const [ activeItem, setActiveItem ] = useState('')
  console.log(activeItem)
  return (
    <>
      <Menu fixed='top' >
        <Container>
          <Menu.Item as='a' header>
            <Image size='mini' src={logo} style={{ marginRight: '1.5em' }} />
            Apigee Configuration Console
          </Menu.Item>
          <Menu.Item as='a'>Home</Menu.Item>

          <Dropdown item simple text='Proxies'>
            <Dropdown.Menu>
              <Menu.Item
                as={Link}
                to="consumer"
                name='Consumer Proxy'
                active={activeItem === 'consumer'}
                onClick={() => setActiveItem('consumer')}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Menu>
      <Container text style={{ marginTop: '7em' }}>
        <Grid.Column stretched width={12}>
            {props.children}
        </Grid.Column>
      </Container>
    </>
  )
}

const Proxies = props => {
  const [ activeItem, setActiveItem ] = useState('')
  return (
    <Grid>
      <Grid.Column width={4}>
        <Header as='h3' icon='plug' content='Apigee Proxies' />
        <Menu fluid vertical tabular>
          <Menu.Item
            as={Link}
            to="consumer"
            name='Consumer Proxy'
            active={activeItem === 'consumer'}
            onClick={() => setActiveItem('consumer')}
          />
          <Menu.Item
            as={Link}
            to="partner"
            name='Partner Proxy'
            active={activeItem === 'partner'}
            onClick={() => setActiveItem('partner')}
          />
        </Menu>
      </Grid.Column>

      <Grid.Column stretched width={12}>
          {props.children}
      </Grid.Column>
    </Grid>
  )
}

const App = () => {
  return (
    <>
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='top'>
        <Grid.Column style={{ maxWidth: 950 }}>
          <Router>
            <Login path="/" />
            <Auth path="/auth"/>
            <ProxyHome path="/home"/>
            <Consumer path="/proxies/consumer" proxyName="consumer"/>
          </Router>
        </Grid.Column>
      </Grid>
    </>
  );
}

export default App;
