
import React from 'react'
import { Message } from 'semantic-ui-react'

const UnexpectedError = () => (
  <Message
    error
    header='Unexpected Error has occured.'
    list={[
      `Your last update request was not applied. Please try the request again.`,
    ]}
  />
)

export default UnexpectedError
