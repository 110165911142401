
import * as constants from '../helpers/constants.js'


console.log('CONSTANTS: ', constants)
const CONFIG_URL = `${constants.PROTOCOL}://${constants.DOMAIN}/${constants.BASE_PATH}`

const endpoint = {
  auth: `${CONFIG_URL}/auth`,
  getConfig: proxyName => `${CONFIG_URL}/proxy/${proxyName}/rate-limit`,
  getSpikeArrest: proxyName => `${CONFIG_URL}/proxy/${proxyName}/spike-arrest`,
  json: `${CONFIG_URL}/json`,
  example: `${CONFIG_URL}/example`,
}

const authDetails = () => {
  return JSON.parse(localStorage.getItem('auth'))
}

const reqArgs = async (verb) => {
  const auth = await authDetails()
  const args = {
    method: verb.toUpperCase(),
    headers: {
      'Content-Type': 'application/json',
      'x-apikey': constants.prodEnv ? constants.client_id : auth.client_id,
      Authorization: `Bearer ${auth.access_token}`,
    }
  }
  
  return args
}

export const updateConfig = async (verb, proxyName, body = null) => {
  console.log('updating configuration...: ', body, verb, proxyName)
  try {
    const request = await reqArgs(verb)
    const jsonBody = (body !== null) ? JSON.stringify(body) : {}
    const response = await fetch(endpoint.getConfig(proxyName), Object.assign({}, request, { body: jsonBody }))

    if (!response.ok) {
      return response
    } else {
      return {}
    }

  } catch (e) {
    throw e
  }
}

export const getConfig = async proxyName => {
  try {
    const request = await reqArgs('GET')
    const response = await fetch(endpoint.getConfig(proxyName), request)
    console.log('response: ', response)
    if (response.status !== 200) {
      console.log("get CONFIG ERROR", response)
      response.error = "Authentication Failure"
      return response
    }
    const jsonPayload = await response.json()
    console.log('jsonPayload: ', jsonPayload)
    return jsonPayload
  } catch (e) {
    throw e
  }
}

export const getSpikeArrest = async proxyName => {
  try {
    const request = await reqArgs('GET')
    const response = await fetch(endpoint.getSpikeArrest(proxyName), request)
    console.log('response: ', response)
    if (response.status !== 200) {
      console.log("get SPIKE ARREST ERROR", response)
      response.error = "Authentication Failure"
      return response
    }
    const jsonPayload = await response.json()
    console.log('jsonPayload: ', jsonPayload)
    return jsonPayload
  } catch (e) {
    throw e
  }
}

export const updateSpikeArrest = async (verb, proxyName, body = null) => {
  console.log('updating spike arrest...: ', body, verb, proxyName)
  try {
    const request = await reqArgs(verb)
    const jsonBody = (body !== null) ? JSON.stringify(body) : {}
    const response = await fetch(endpoint.getSpikeArrest(proxyName), Object.assign({}, request, { body: jsonBody }))

    if (!response.ok) {
      return response
    } else {
      return {}
    }

  } catch (e) {
    throw e
  }
}

export const authenticate = async (username, password) => {
  try {
    console.log('auth:', username, password)
    const base64Auth = btoa(`${username}:${password}`)
    const response = await fetch(endpoint.auth, {
      // credentials: 'same-origin', // fetch won't send cookies unless you set credentials
      method: 'POST',
      headers: {
        Authorization: `Basic ${base64Auth}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(`grant_type=client_credentials`)
    })
    if (!response.ok) {
      return {
        ok: response.ok,
        statusText: response.statusText,
      }
    }

    const jsonPayload = await response.json()
    localStorage.setItem('auth', JSON.stringify(jsonPayload))
    return {
      ok: response.ok,
      statusText: response.statusText,
    }
  } catch (e) {

  }
}
