import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';
import { Label } from 'semantic-ui-react'

const STEP = 25;
const MIN = 0;
const MAX = 100;

class Labeled extends React.Component {
  state = {
    values: [this.props.value]
  };
  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}
      >
        <Range
          key={`${this.props.service}-${this.props.client}`}
          values={this.state.values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={values => this.setState({ values })}
          onFinalChange={() => this.props.onChange(this.state.values[0], this.props.service, this.props.client)}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%'
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '2px',
                  width: '100%',
                  borderRadius: '10px',
                  background: getTrackBackground({
                    values: this.state.values,
                    colors: ['#548BF4', '#ccc'],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: 'center'
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <>
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '30px',
                  width: '30px',
                  backgroundColor: '#FFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 0px 2px #AAA',
                  borderRadius: '50%'
                }}
              >
                {isDragged &&
                <div style={{top: '-37px', position: 'absolute'}}>
                  <Label basic color='blue' pointing='below'>
                    {this.state.values[0].toFixed(0)}
                  </Label>
                </div>
                }
                <div
                  style={{
                    height: '6px',
                    width: '1px',
                    backgroundColor: isDragged ? '#548BF4' : '#CCC'
                  }}
                />
              </div>
            </>
          )}
        />
      </div>
    );
  }
}

export default Labeled;
