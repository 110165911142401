import React, { useState } from 'react'
import { Container, Tab } from 'semantic-ui-react'

import MenuBar from '../../components/MenuBar'
import ProxyContext from '../../components/ProxyContext'
import SpikeArrest from "../../components/SpikeArrest"
import Throttler from "../../components/Throttler"
import UnauthorizedBanner from '../../components/UnauthorizedBanner'

const Consumer = props => {
  const [authError, setIsAuthError] = useState(false)
  const { name, email } = JSON.parse(localStorage.auth)

  const contextValues = {
    proxyName: props.proxyName,
    setIsAuthError: setIsAuthError,
    name: name,
    email: email
  }

  const panes = [
    { menuItem: 'Throttling', render: () => <Tab.Pane><Throttler/></Tab.Pane> },
    { menuItem: 'Spike Arrest', render: () => <Tab.Pane><SpikeArrest/></Tab.Pane> },
  ]

  return (
    <ProxyContext.Provider value={contextValues}>
      <Container>
        {authError && <UnauthorizedBanner />}

        {!authError && <MenuBar name={name}/>}

        {!authError && <Tab style={{ marginTop: '4em' }} menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />}
      </Container>
    </ProxyContext.Provider>
  )
}

export default Consumer
