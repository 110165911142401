import React from 'react'
import { navigate } from "@reach/router"
import { Message, Container, Button, Segment, Grid, Image } from 'semantic-ui-react';
import logo from '../../images/paybyphone-logo.png';


export default ({ location }) => {
  return (
    <Container text style={{ marginTop: '7em' }}>
      <Grid.Column stretched width={12}>
        <Segment textAlign='center'>
          <Message positive>
            <Message.Header><Image size='mini' src={logo} style={{ marginRight: '1.5em' }} />Apigee Configuration Console</Message.Header>
            <Button style={{ marginTop: '1.5em' }} inverted color='green' onClick={() => { navigate('/') }}> Sign In </Button>
          </Message>
        </Segment>
      </Grid.Column>
    </Container>
  )
}
