import React, { useState, useEffect, useContext } from 'react'
import isEqual from 'lodash/isEqual'
import { capitalize } from '../helpers'
import { Message, Container, Icon, Popup, Table, Radio, Segment, Grid, Label, Loader, Dimmer, Button } from 'semantic-ui-react'
import * as CONSTANT from '../helpers/constants'
import { getSpikeArrest, updateSpikeArrest } from '../config/api'
import ConfirmationModal from './ConfirmationModal'
import ProxyContext from './ProxyContext'
import SpikeArrestInput from './SpikeArrestInput'
import UnexpectedError from './UnexpectedError'

export const SpikeArrestContext = React.createContext()

const SpikeArrest = () => {
  const [proxyConfig, setProxyConfig] = useState(false)
  const [spikeArrestLimits, setSpikeArrestLimits] = useState({})
  const [fetchedSpikeArrestLimits, setFetchedSpikeArrestLimits] = useState({})
  const [shouldToggleSpikeArrest, setShouldToggleSpikeArrest] = useState(false)
  const [isSpikeArrestEnabled, setIsSpikeArrestEnabled] = useState(false)
  const [isConfigUpdating, setIsConfigUpdating] = useState(false)
  const [isPresave, setIsPresave] = useState(false)
  const [isUpdateError, setIsUpdateError] = useState(false)
  
  const context = useContext(ProxyContext)

  useEffect(() => {
    const fetchData = async () => await refreshSpikeArrestState()
    fetchData()
  }, []);

  const refreshSpikeArrestState = async () => {
    console.log('Refreshing throttler state..')
    try {
      setIsConfigUpdating(true)
      const result = await getSpikeArrest(context.proxyName)
      console.log('result: ', result)
      if (result.error) {
        context.setIsAuthError(true)
      }
      setProxyConfig(result);
      setIsSpikeArrestEnabled(result.isSpikeArrestEnabled)
      setSpikeArrestLimits({ ...result.spikeArrestLimits })
      setFetchedSpikeArrestLimits({ ...result.spikeArrestLimits });
      setShouldToggleSpikeArrest(false)
      setIsConfigUpdating(false)
    } catch (e) {
      console.log(e)
    }
  }

  const handleSaveConfig = async (rules = spikeArrestLimits) => {
    try {
      setIsConfigUpdating(true)	
      const payload = await updateSpikeArrest('put', context.proxyName, { spikeArrestLimits: { ...rules }, isSpikeArrestEnabled: shouldToggleSpikeArrest ? !isSpikeArrestEnabled : isSpikeArrestEnabled })
      if (!!payload.status) {
        setIsUpdateError(true)
      }
      await refreshSpikeArrestState()
      setIsConfigUpdating(false)
      setIsPresave(false)
    } catch (e) {
      console.log(e)
      return e
    }
  }

  const handleCancelConfig = () => {
    setShouldToggleSpikeArrest(false)
  }

  const handleSpikeArrestToggle = async () => {
    setShouldToggleSpikeArrest(true)
    setIsPresave(true)
  }
  
  const SpikeArrestNotification = () => {
    return (
      <Message color={isSpikeArrestEnabled ? 'orange' : 'green'}>
        <Message.Header>Spike Arrest is {isSpikeArrestEnabled ? 'currently' : 'not'} enabled</Message.Header>
        { (isSpikeArrestEnabled)
          ?
          <>
            <p> Apigee is currently limiting requests per minute (spike arrests) to PayByPhone services.</p>
          </>
          :
          <>
            <p>Apigee's spike arrest policy is currently not throttling any traffic.</p>
          </>
        }
      </Message>
    )
  }

  const isConfigModified = () => {
    const equal = isEqual(spikeArrestLimits, fetchedSpikeArrestLimits)
    console.log('isConfigModified returns: ', equal)
    return equal
  }

  const calcIsConfigModified = isConfigModified()

  const handleOnSubmit = async () => {
    setIsPresave(true)
  }

  const handleResetConfig = () => {
    setSpikeArrestLimits(fetchedSpikeArrestLimits)
  }

  const renderSpikeArrestLimits = (path) => {
    const pathLimits = spikeArrestLimits[path]
    // If the first nested layer is an array or value, that means the path's spike arrest limit applies to any client
    if (Array.isArray(pathLimits) || Number.isInteger(pathLimits)) {
      return <SpikeArrestInput key={path} client='Any' path={path} limits={pathLimits}></SpikeArrestInput>
    }
    // If the second nested layer is an array or value, that means the path's spike arrest limits are separated by client type
    else if (Array.isArray(pathLimits[Object.keys(pathLimits)[0]])  || Number.isInteger(pathLimits[Object.keys(pathLimits)[0]])) {
      return Object.keys(pathLimits).map(client => (
        <SpikeArrestInput key={`${path}_${client}`} client={client} path={path} limits={pathLimits[client]}></SpikeArrestInput>
      ))
    }
    // Existence of a third nested layer means the spike arrest limits must also be separated by request verb
    else {
      return Object.keys(pathLimits).map(client => (
        <>
          <Grid.Row>
            <Grid.Column width={3} >
              <Label><h4>{capitalize(client)}</h4></Label>
            </Grid.Column>
            <Grid.Column width={13}/>
          </Grid.Row>
          {Object.keys(pathLimits[client]).map(verb => (
            <SpikeArrestInput key={`${path}_${client}_${verb}`} verb={verb} client={client} path={path} limits={pathLimits[client][verb]}></SpikeArrestInput>
          ))}
        </>
      ))}
  }

  let contextValues = { setSpikeArrestLimits: setSpikeArrestLimits, spikeArrestLimits: spikeArrestLimits }

  return (
    <Container text style={{ width: '100%' }}>
      <Segment>
        <ConfirmationModal isConfigUpdating={isConfigUpdating} setPresave={setIsPresave} active={isPresave} onSave={handleSaveConfig} onCancel={handleCancelConfig} />
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={2}>Spike Arrest Status</Table.Cell>
              <Table.Cell> <Label> {isSpikeArrestEnabled ? 'ENABLED' : 'DISABLED'} </Label> </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Apigee Proxy</Table.Cell>
              <Table.Cell>{context.proxyName.toUpperCase()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Environment</Table.Cell>
              <Table.Cell>{CONSTANT.APIGEE_ENV.toUpperCase()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>User</Table.Cell>
              <Table.Cell>{context.name} ({context.email})</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={4} textAlign="center"><a href={CONSTANT.DATADOG_URL}>Datadog Monitoring Dashboard</a></Table.Cell>
              <Table.Cell width={4} textAlign="center"><a href={CONSTANT.CONFLUENCE_URL}>Instructions (Confluence)</a></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Grid.Column verticalAlign="top" stretched width={12}>
          <Segment style={{ display: 'flex', justifyContent: 'center' }}>
            <Radio style={{ marginRight: '2px' }} toggle checked={isSpikeArrestEnabled} onChange={handleSpikeArrestToggle} />
            <Popup
              content="Spike Arrest will enforce a limit on incoming requests per minute."
              trigger={<Icon color="grey" name="question circle outline" size="small" />}
            />
            {isSpikeArrestEnabled ? 'Turn OFF to enable 100% traffic (no spike arrest limits)' : 'Turn ON to enable spike arrests (limiting requests per minute)'}
          </Segment>
          {!isConfigUpdating && <SpikeArrestNotification />}
        </Grid.Column>
        {isUpdateError && <UnexpectedError />}
        {!Object.keys(spikeArrestLimits).length ?
          <>
            <Loader size='massive' active={isConfigUpdating}> LOADING
              <Dimmer.Dimmable blurring dimmed={true}>
                <p>{JSON.stringify(proxyConfig)}</p>
              </Dimmer.Dimmable>
            </Loader>
          </>
          :
          <>
            <Grid celled as={Dimmer.Dimmable} dimmed={isConfigUpdating}>
              {Object.keys(spikeArrestLimits).map(path => {
                return (
                  <Grid.Row key={path}>
                    <Grid.Column>
                      <Grid verticalAlign='middle' columns={1}>
                        <Grid.Row>
                          <Grid.Column width={3}>
                            <Label as='a' ribbon>
                              <h3>
                                {path}
                              </h3>
                            </Label>
                          </Grid.Column>
                        </Grid.Row>
                        <SpikeArrestContext.Provider value={contextValues}>{renderSpikeArrestLimits(path)}</SpikeArrestContext.Provider>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                )
              })}
            </Grid>
            <Grid.Column stretched width={12}>
              <Segment>
                <Button primary loading={isConfigUpdating} disabled={calcIsConfigModified} onClick={handleOnSubmit}>Save Spike Arrest Configuration</Button>
                <Button secondary disabled={calcIsConfigModified || isConfigUpdating} onClick={handleResetConfig}>Reset</Button>
              </Segment>
            </Grid.Column>
          </>
        }
      </Segment>
    </Container>
  )
}

export default SpikeArrest
