import React, { useState } from 'react'
import * as yup from 'yup';
import { Icon, Dimmer, Loader, Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import * as oidcApi from "../config/oidcApi";
import { nanoid } from 'nanoid'
import logo from '../images/paybyphone-logo.png'; // Tell webpack this JS file uses this image
import { prodEnv } from "../helpers/constants"
import { navigate } from '@reach/router';
import { authenticate } from '../config/api'
import { Formik } from 'formik';

let LoginSchema = yup.object().shape({
  username: yup.string().required('This field is required.'),
  password: yup.string().required('Password field is required.')
});

const LoginForm = () => {
  const [authError, setAuthError] = useState({})

  const state = nanoid(32)
  const nonce = nanoid(32)

  if (prodEnv) {
    oidcApi.beginAuth({ state, nonce })
  }

  const Loading = () => {
    return (
      <>
        <Image size='small' centered src={logo} />
        <Header as='h2' color='green' textAlign='center'>
          <Icon loading name='spinner' />
          Logging in via OneLogin
        </Header>
      </>
    )
  }

  const LoginForm = () => {
    return (
      <Grid centered style={{ marginTop: '7em' }}>
        <Grid.Column width={8}>
          <Image size='small' centered src={logo} />
          <Header as='h2' color='green' textAlign='center'>
            Log in to API Gateway Console
        </Header>
          <Formik
            initialValues={{ username: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setAuthError({})
              const payload = await authenticate(values.username, values.password)
              setSubmitting(false);
              if (payload.ok) {
                navigate('/proxies/consumer', { state: { proxyName: 'consumer' } })
                setAuthError('')
              }
              if (!payload.ok) {
                setAuthError({
                  header: 'Oops',
                  content: `Unable to authenticate: ${payload.statusText}.
                Please review your credentials and try again.` })
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (

              <Form size='large' onSubmit={handleSubmit} error={!!Object.keys(authError).length}>
                <Segment raised>
                  <Dimmer active={isSubmitting} inverted>
                    <Loader size='medium'>Verifiying</Loader>
                  </Dimmer>
                  <Form.Input
                    name='username'
                    type='username'
                    fluid
                    icon='user'
                    iconPosition='left'
                    placeholder='Username'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    error={errors.username && touched.username && errors.username && { content: errors.username }}
                  />

                  <Form.Input
                    fluid
                    name='password'
                    icon='lock'
                    iconPosition='left'
                    placeholder='Password'
                    type='password'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={errors.password && touched.password && errors.password && { content: errors.password }}
                  />

                  <Button color='green' fluid size='large' disabled={isSubmitting}>
                    Login
                </Button>
                </Segment>
                <Message
                  error
                  header={authError.header}
                  content={authError.content}
                />
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid>
    )
  }

  return (
    <>
      { prodEnv ? <Loading /> : <LoginForm />}
    </>
  )
}

export default LoginForm
