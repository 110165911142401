import React, { useState, useEffect, useContext } from 'react'
import isEqual from 'lodash/isEqual'
import _ from "lodash"
import { Message, Container, Icon, Popup, Button, Table, Radio, Segment, Grid, Loader, Dimmer, Label } from 'semantic-ui-react'
import { capitalize, sum } from '../helpers'
import * as CONSTANT from '../helpers/constants'
import { updateConfig, getConfig } from '../config/api'
import ConfirmationModal from './ConfirmationModal'
import ProxyContext from './ProxyContext'
import Slider from './Slider'
import UnexpectedError from './UnexpectedError'

const Throttler = () => {
  const [proxyConfig, setProxyConfig] = useState(false)
  const [isThrottlerEnabled, setIsThrottlerEnabled] = useState(false)
  const [routingRules, setRoutingRules] = useState({})
  const [fetchedRoutingRules, setFetchedRoutingRules] = useState({})
  const [isConfigUpdating, setIsConfigUpdating] = useState(false)
  const [isPresave, setIsPresave] = useState(false)
  const [isUpdateError, setIsUpdateError] = useState(false)

  const context = useContext(ProxyContext)

  useEffect(() => {
    const fetchData = async () => await refreshThrottlerState()
    fetchData()
  }, []);

  const refreshThrottlerState = async () => {
    console.log('Refreshing throttler state..')
    try {
      setIsConfigUpdating(true)
      const result = await getConfig(context.proxyName)
      console.log('result: ', result)
      if (result.error) {
        context.setIsAuthError(true)
      }
      setProxyConfig(result);
      setIsThrottlerEnabled(result.isThrottlerEnabled)
      setRoutingRules({ ...result.routingRules })
      setFetchedRoutingRules({ ...result.routingRules });
      setIsConfigUpdating(false)
    } catch (e) {
      console.log(e)
    }
  }

  const handleValueChange = (value, service, client) => {
    setRoutingRules({ ...routingRules, [service]: { ...routingRules[service], [client]: value } })
  };

  const modify = (service, value) => {
    console.log("\n setServiceToZero for ", service)
    const srvc = _.cloneDeep(routingRules[service])
    for (let clientType in srvc) {
      srvc[clientType] = value
    }
    return { ...routingRules, [service]: { ...srvc } }
  }

  const handleServiceKillSwitch = (service, e) => {
    console.log(service, "\n", routingRules)
    !!Object.values(routingRules[service]).reduce((a, c) => a += c, 0)
      ? setRoutingRules(modify(service, 0))
      : setRoutingRules(modify(service, 100))
  }

  const isServiceThrottled = (service) => {
    console.log(service, "routingrules", routingRules);
    //the kill switch is enabled for the proxy
    //traffic from all client types is 0
    //the toggle switch is set to off
    return (Object.values(routingRules[service]).reduce((a, c) => a += c, 0) == 0) // true is sum of all values are zero 
  }

  const handleAllServices = () => {
    !!isTrafficThrottled(routingRules)
      ? setAll(100)
      : setAll(0)

    setIsPresave(true)
  }

  const setAll = function (value) {
    const newRoutingRules = _.cloneDeep(routingRules)
    const services = Object.keys(routingRules)
    services.forEach(service => {
      for (let clientType in newRoutingRules[service]) {
        newRoutingRules[service][clientType] = value
      }
    })

    setRoutingRules({ ...routingRules, ...newRoutingRules })
  }

  const handleThrottlerToggle = async () => {
    setIsConfigUpdating(true)
    if (isThrottlerEnabled) {
      console.log('disabling throttler')
      const response = await updateConfig('delete', context.proxyName)
    }
    await refreshThrottlerState()
    setIsThrottlerEnabled(!isThrottlerEnabled)
  }

  const handleSaveConfig = async (rules = routingRules, e) => {
    try {
      console.log('handleSaveConfig')
      setIsConfigUpdating(true)
      const payload = await updateConfig('put', context.proxyName, { routingRules: { ...rules }, isThrottlerEnabled: true })
      if (!!payload.status) {
        setIsUpdateError(true)
      }
      await refreshThrottlerState()
      setIsConfigUpdating(false)
      setIsPresave(false)
    } catch (e) {
      console.log(e)
      return e
    }
  }

  const handleCancelConfig = () => {
  }

  const handleOnSubmit = async () => {
    const throttle = isTrafficThrottled(routingRules)
    if (!throttle) {
      await handleSaveConfig()
    } else {
      setIsPresave(true)
    }
  }

  const handleResetConfig = () => {
    setRoutingRules(fetchedRoutingRules)
  }

  const isConfigModified = () => {
    const equal = isEqual(routingRules, fetchedRoutingRules)
    console.log('isConfigModified returns: ', equal)
    return equal
  }

  const isTrafficThrottled = (rules) => {
    let desiredTotal = 0
    let actualTotal = 0
    Object.keys(rules).forEach(service => {
      Object.keys(rules[service]).forEach(client => {
        desiredTotal += 100
        actualTotal += rules[service][client]
      })
    })
    console.log('desired: ', desiredTotal, actualTotal, desiredTotal === actualTotal)
    return desiredTotal !== actualTotal
  }

  const servicesAtZero = () => {
    let a = Object.keys(routingRules).some(service => {
      return (sum(Object.values(service)) === 0)
    })
    console.log("servicesAtZero:", routingRules)
    return a
  }

  const MasterKillSwitch = (props) => {
    return (
      <>
        <Grid.Column stretched width={4}>
          <Button inverted color='red' onClick={handleAllServices}> {props.active ? 'ENABLE ALL TRAFFIC' : 'DISABLE ALL TRAFFIC'} </Button>
        </Grid.Column>
      </>
    )
  }

  const ThrottlingNotification = () => {
    const trafficThrottled = isTrafficThrottled(fetchedRoutingRules)
    return (
      <Message color={trafficThrottled ? 'orange' : 'green'}>
        <Message.Header>{trafficThrottled ? 'Traffic' : 'No traffic'} is currently being throttled</Message.Header>
        { (trafficThrottled)
          ?
          <>
            <p> Apigee is currently throttling upstream traffic to PayByPhone services.</p>
            { servicesAtZero && <MasterKillSwitch active={trafficThrottled} />}
          </>
          :
          <>
            <p>Apigee's rate limiting policy is currently not throttling any traffic.</p>
            { isThrottlerEnabled && <MasterKillSwitch active={trafficThrottled} />}
          </>
        }
      </Message>
    )
  }

  const ServiceKillSwitch = ({ service }) => (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <Label as='a' inverted ribbon>
              <h3>
                <Radio toggle checked={!isServiceThrottled(service)} onChange={(e) => handleServiceKillSwitch(service, e)} label={capitalize(service)} />
                {service === 'other' &&
                  <Popup
                    content="These route rules apply to all services other than Identity, Parking or Payment"
                    trigger={<Icon color="grey" name="question circle outline" size="small" style={{ padding: '0 1.0rem' }}/>}
                  />
                }
              </h3>
            </Label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )

  const ProxySlider = ({ service, client, value }) => {
    const isUnsaved = (value => Object.keys(fetchedRoutingRules).length && value !== fetchedRoutingRules[service][client])(value)
    return (
      <Grid.Row>
        <Grid.Column width={3} >
          <h5>{capitalize(client)}</h5>
        </Grid.Column>
        <Grid.Column width={8}>
          <Slider key={`${service}-${client}`} service={service} client={client} onChange={handleValueChange} value={value} color="red" />
        </Grid.Column>
        <Grid.Column width={5}>
          <Label color={value === 100 ? "blue" : "orange"}>{value}%</Label>
          {isUnsaved &&
            <Label color={isUnsaved ? "red" : "blue"}>Unsaved</Label>
          }
        </Grid.Column>
      </Grid.Row>
    )
  }

  const calcIsConfigModified = isConfigModified()

  return (
    <Container text style={{ width: '100%' }}>
      <Segment>
        <ConfirmationModal isConfigUpdating={isConfigUpdating} setPresave={setIsPresave} active={isPresave} onSave={handleSaveConfig} onCancel={handleCancelConfig} />
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={2}>Throttler Status</Table.Cell>
              <Table.Cell> <Label inverted> {isThrottlerEnabled ? 'ENABLED' : 'DISABLED'} </Label> </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Apigee Proxy</Table.Cell>
              <Table.Cell>{context.proxyName.toUpperCase()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Environment</Table.Cell>
              <Table.Cell>{CONSTANT.APIGEE_ENV.toUpperCase()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>User</Table.Cell>
              <Table.Cell>{context.name} ({context.email})</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={4} textAlign="center"><a href={CONSTANT.DATADOG_URL}>Datadog Monitoring Dashboard</a></Table.Cell>
              <Table.Cell width={4} textAlign="center"><a href={CONSTANT.CONFLUENCE_URL}>Instructions (Confluence)</a></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Grid.Column verticalAlign="top" stretched width={12}>
          <Segment style={{ display: 'flex', justifyContent: 'center' }}>
            <Radio style={{ marginRight: '2px' }} toggle checked={isThrottlerEnabled} onChange={handleThrottlerToggle} />
            <Popup
              content="Turn OFF for to allow ALL traffic to proceed (no rate limiting)"
              trigger={<Icon color="grey" name="question circle outline" size="small" />}
            />
            {isThrottlerEnabled ? 'Turn OFF means 100% traffic (no throttling)' : 'Turn ON to start throttling'}
          </Segment>
          {!isConfigUpdating && <ThrottlingNotification />}
        </Grid.Column>
        {isUpdateError && <UnexpectedError />}
        {!Object.keys(routingRules).length ?
          <>
            <Loader size='massive' active={isConfigUpdating}> LOADING
              <Dimmer.Dimmable blurring dimmed={true}>
                <p>{JSON.stringify(proxyConfig)}</p>
              </Dimmer.Dimmable>
            </Loader>
          </>
          :
          <>
            {isThrottlerEnabled &&
              <Grid celled as={Dimmer.Dimmable} dimmed={isConfigUpdating}>
                {Object.keys(routingRules).map(service => {
                  return (
                    <Grid.Row key={service}>
                      <Grid.Column>
                        <Grid verticalAlign='middle' columns={1}>
                          <Grid.Row>
                            <Grid.Column>
                              <ServiceKillSwitch service={service} />
                            </Grid.Column>
                          </Grid.Row>
                          {Object.keys(routingRules[service]).map(client => (
                            <ProxySlider key={`${service}-${client}`} service={service} client={client} value={routingRules[service][client]} />
                          ))}
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  )
                })}
              </Grid>
            }
          </>
        }
        {isThrottlerEnabled &&
          <Grid.Column stretched width={12}>
            <Segment>
              <Button primary loading={isConfigUpdating} disabled={calcIsConfigModified} onClick={handleOnSubmit}>Save Throttling Configuration</Button>
              <Button secondary disabled={calcIsConfigModified || isConfigUpdating} onClick={handleResetConfig}>Reset</Button>
            </Segment>
          </Grid.Column>
        }
      </Segment>
    </Container>
  )
}

export default Throttler
