import React, { useContext, useState, useEffect } from 'react'
import { capitalize } from '../helpers'
import { Icon, Popup, Radio, Grid, Input } from 'semantic-ui-react'
import { SpikeArrestContext } from './SpikeArrest'

const SpikeArrestInput = ({ verb, client, path, limits }) => {
  const [value, setValue] = useState(0)
  const [monitor, setMonitor] = useState(true)

  const context = useContext(SpikeArrestContext)

  useEffect(() => {
    if (Array.isArray(limits)) {
      setValue(limits[0])
      setMonitor(limits[1])
    }
    else {
      setValue(limits)
      setMonitor(false)
    }
  }, [limits])

  // const isUnsaved = client === 'Any' ? (value => Object.keys(fetchedSpikeArrestLimits).length && limits !== fetchedSpikeArrestLimits[path])(value)
  // 	: (() => Object.keys(fetchedSpikeArrestLimits).length && limits !== fetchedSpikeArrestLimits[path][client])(value)

  const handleChangeValue = e => {
    const newValue = Number.isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
    // Set the new spike arrest limit value based on how deeply nested the changed value is
    verb ? context.setSpikeArrestLimits({ ...context.spikeArrestLimits, [path]: { ...context.spikeArrestLimits[path], [client]: { ...context.spikeArrestLimits[path][client], [verb]: [ newValue, monitor ] }}})
      : (client !== 'Any') ? context.setSpikeArrestLimits({ ...context.spikeArrestLimits, [path]: { ...context.spikeArrestLimits[path], [client]: [ newValue, monitor ] }})
      : context.setSpikeArrestLimits({ ...context.spikeArrestLimits, [path]: [ newValue, monitor ] })
    setValue(newValue)
  }

  const handleChangeMonitor = e => {
    // Set the new spike arrest monitor value based on how deeply nested the changed value is
    verb ? context.setSpikeArrestLimits({ ...context.spikeArrestLimits, [path]: { ...context.spikeArrestLimits[path], [client]: { ...context.spikeArrestLimits[path][client], [verb]: [ value, !monitor ] }}})
      : (client !== 'Any') ? context.setSpikeArrestLimits({ ...context.spikeArrestLimits, [path]: { ...context.spikeArrestLimits[path], [client]: [ value, !monitor ] }})
      : context.setSpikeArrestLimits({ ...context.spikeArrestLimits, [path]: [ value, !monitor ] })
    setMonitor(!monitor)
  }

  return (
    <Grid.Row>
      <Grid.Column width={3} >
        <h5>{ verb ? verb.toUpperCase() : capitalize(client) }</h5>
      </Grid.Column>
      <Grid.Column width={7}>
        <Input value={value} onChange={handleChangeValue}/>
      </Grid.Column>
      <Grid.Column width={6} style={{textAlign: 'left'}} >
        <Radio toggle label={ monitor ? 'OFF (Monitor Only)' : 'ENABLED' } checked={!monitor} onChange={handleChangeMonitor}></Radio>
        <Popup
            content="When set to OFF (Monitor Only), spike arrests will not be enforced and no traffic will be throttled. Logs will continue to flow to Datadog."
            trigger={<Icon color="grey" name="question circle outline" size="small" />}
        />
        {/* {isUnsaved &&
          <Label color="red">Unsaved</Label>
        } */}
      </Grid.Column>
    </Grid.Row>
  )
}

export default SpikeArrestInput
